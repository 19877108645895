<script lang="ts" setup>
import { PropType } from 'vue'
import { EmitsEnum } from '~/constants/emits'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { utilAccount } from '~~/src/utils/utilAccount'
import { TestIds } from '../../constants/TestIds'

const props = defineProps({
	value: { type: String, default: '' },
	placeholder: { type: String as PropType<TranslationKey> },
	label: { type: String as PropType<TranslationKey>, default: undefined },
	customError: { type: String as PropType<TranslationKey> },
	helperText: { type: String as PropType<TranslationKey> },
	inputTestId: { type: String as PropType<keyof typeof TestIds>, default: undefined },
})
const { value, placeholder: _placeholder, customError, label, helperText } = toRefs(props)

const emit = defineEmits([EmitsEnum.Change])
const onChange = (val: any) => {
	const text = val.target.value
	value.value = text
	emit(EmitsEnum.Change, text)
}

const placeholder = computed(() =>
	useLocale()
		.translate(_placeholder?.value || TranslationKeys.CHOSE_AN_OPTION)
		.toString()
)
const isValidMail = computed(() => value.value.length > 0 && !utilAccount.isValidMail(value.value))

defineExpose({
	hasError: () => isValidMail.value,
})
</script>

<template>
	<div class="flex flex-col gap-1">
		<CommonLabel :text="label">
			<div class="relative">
				<CommonIcon
					v-if="customError != undefined"
					:icon-name="SpSvg.BasicErrorCircle"
					class="absolute right-3 top-1/2"
					style="transform: translateY(-50%)"
					fill="fill-error-400"
				/>
				<input
					type="email"
					:value="value"
					:placeholder="placeholder"
					:class="`border-1 border bg-sky-100 text-sm ${customError != undefined ? 'border-2 border-error-600 outline-error-600' : 'border-sky-200 outline-sky-200'} w-full rounded-md p-2`"
					@input="onChange"
					@change="onChange"
					:data-testid="inputTestId && TestIds[inputTestId]"
				/>
			</div>
			<CommonText v-if="customError != undefined" class="text-error-600" :text="customError" />
			<CommonText v-if="helperText != undefined" class="text-sky-500" :text="helperText" />
		</CommonLabel>
	</div>
</template>
